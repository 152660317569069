import { API } from "aws-amplify";

export default {
    namespaced: true,
    state :{
        affiliateLinks: []
    },
    mutations:{
        SET_AFFILIATE_LINKS(state, data){
            state.affiliateLinks = data
            console.log(state.affiliateLinks);
        },
    },
    getters:{
        affiliateLinks(state){
            return state.affiliateLinks;
        },
    },
    actions:{
        getAffiliateLinks({commit}, personType){
            API.get("peoplePortalRestAPI", `/affiliate/links/${personType}`).then((res) => {
                if(res.status === "200"){
                    commit("SET_AFFILIATE_LINKS", res.data)
                }
            })
        }
    }
}
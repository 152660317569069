import { API } from "aws-amplify";

export default {
    namespaced: true,
    state :{
        buyer: ''
    },
    mutations:{
        SET_SELECTED_BUYER(state, data){
            state.buyer = data;
        }
    },
    getters:{
        buyer(state){
            return state.buyer;
        },
        linkedInstructionsCount(state){
            if(!state.buyer){
                return 0;
            }
            else if(!state.buyer.Instructions){
                return 0;
            }
            else{
                return state.buyer.Instructions.length;
            }
        },
        instructions(state){
            if(!state.buyer){
                return null;
            }
            else if(!state.buyer.Instructions){
                return [];
            }
            else{
                return state.buyer.Instructions;
            }
        }
    },
    actions:{
        getBuyer({commit}, payload){
            const params = {body: {
                clientName : payload.clientName,
                buyerID : payload.buyerID             
            }}
            API.post("peoplePortalRestAPI", "/accounts/buyer", params).then((res) => {
                if(res.status === "200"){
                    commit("SET_SELECTED_BUYER", res.data)
                }
            })
        },
        async updatePreferences({dispatch}, payload){
            return new Promise((resolve) => { 
                const params = {body: {
                    clientName : payload.clientName,
                    buyerID : payload.buyerID,
                    MinimumBedrooms: payload.MinimumBedrooms,
                    MinimumBathrooms: payload.MinimumBathrooms,
                    MatchPropertyType: payload.MatchPropertyType,
                    MaximumPrice: payload.MaximumPrice
                }}
                API.patch("peoplePortalRestAPI", "/accounts/buyer/preferences", params).then((res) => {
                    dispatch("getBuyer", { 
                        clientName : payload.clientName,
                        buyerID : payload.buyerID,
                    });
                    if(res.status === "200"){
                        resolve({
                            snackbarSuccess : true,
                            showSnackbar : true,
                        })
                    }
                    else{
                        resolve({
                            snackbarSuccess : false,
                            showSnackbar : true,

                        })
                    }
                }).catch(() => {
                    resolve({
                        snackbarSuccess : false,
                        showSnackbar : true,

                    })
                })
            })
        },
        async updateSituation({dispatch}, payload){
            return new Promise((resolve) => { 
                const params = {body: {
                    clientName : payload.clientName,
                    buyerID : payload.buyerID,
                    PurchaseReason: payload.PurchaseReason,
                    BuyingPosition: payload.BuyingPosition,
                    MortgageOffered: payload.MortgageOffered,
                    SellingPosition: payload.SellingPosition
                }}
                API.patch("peoplePortalRestAPI", "/accounts/buyer/situation", params).then((res) => {
                    dispatch("getBuyer", { 
                        clientName : payload.clientName,
                        buyerID : payload.buyerID,
                    });
                    if(res.status === "200"){
                        resolve({
                            snackbarSuccess : true,
                            showSnackbar : true,
                        })
                    }
                    else{
                        resolve({
                            snackbarSuccess : false,
                            showSnackbar : true,

                        })
                    }
                }).catch(() => {
                    resolve({
                        snackbarSuccess : false,
                        showSnackbar : true,

                    })
                })
            })
        },
        async updatePropertyAlertSettings({dispatch}, payload){
            return new Promise((resolve) => { 
                const params = {body: {
                    clientName : payload.clientName,
                    buyerID : payload.buyerID,
                    Mobile: payload.Mobile,
                    Subscribed: payload.Subscribed,
                }}
                API.patch("peoplePortalRestAPI", "/accounts/buyer/propertyalerts", params).then((res) => {
                    dispatch("getBuyer", { 
                        clientName : payload.clientName,
                        buyerID : payload.buyerID,
                    });
                    if(res.status === "200"){
                        resolve({
                            snackbarSuccess : true,
                            showSnackbar : true,
                        })
                    }
                    else{
                        resolve({
                            snackbarSuccess : false,
                            showSnackbar : true,

                        })
                    }
                }).catch(() => {
                    resolve({
                        snackbarSuccess : false,
                        showSnackbar : true,

                    })
                })
            })
        },
        async updateFeedback({dispatch}, payload){
            return new Promise((resolve) => { 
                const params = {body: {
                    clientName : payload.clientName,
                    buyerID : payload.buyerID,
                    viewingID: payload.viewingID,
                    propertyID: payload.propertyID,
                    Feedback: payload.Feedback,
                    ViewingDate: payload.ViewingDate,
                }}
                console.log(params);
                API.patch("peoplePortalRestAPI", "/accounts/buyer/feedback", params).then((res) => {
                    dispatch("getBuyer", { 
                        clientName : payload.clientName,
                        buyerID : payload.buyerID,
                    });
                    if(res.status === "200"){
                        resolve({
                            snackbarSuccess : true,
                            showSnackbar : true,
                        })
                    }
                    else{
                        resolve({
                            snackbarSuccess : false,
                            showSnackbar : true,

                        })
                    }
                }).catch(() => {
                    resolve({
                        snackbarSuccess : false,
                        showSnackbar : true,

                    })
                })
            })
        },
        async submitOffer({dispatch}, payload){
            return new Promise((resolve) => { 
                const params = {body: {
                    clientName : payload.supplementaryInformation.clientName,
                    buyerID : payload.supplementaryInformation.buyerID,
                    PropertyOID: payload.submissionModel.PropertyOID,
                    OfferAmount: payload.submissionModel.OfferAmount,
                    MortgageRequired: payload.submissionModel.MortgageRequired,
                    MortgageDetails: payload.submissionModel.MortgageDetails,
                    SpecialConditions: payload.submissionModel.SpecialConditions
                }}
                console.log(params);
                API.post("peoplePortalRestAPI", "/accounts/buyer/offer", params).then((res) => {
                    dispatch("getBuyer", { 
                        clientName : payload.supplementaryInformation.clientName,
                        buyerID : payload.supplementaryInformation.buyerID,
                    });
                    if(res.status === "200"){
                        resolve({
                            snackbarSuccess : true,
                            showSnackbar : true,
                        })
                    }
                    else{
                        resolve({
                            snackbarSuccess : false,
                            showSnackbar : true,

                        })
                    }
                }).catch(() => {
                    resolve({
                        snackbarSuccess : false,
                        showSnackbar : true,

                    })
                })
            })
        },
    }
}
import { API } from "aws-amplify";

export default {
    namespaced: true,
    state :{
        inbox: ''
    },
    mutations:{
        SET_LOADED_INBOX(state, data){
            data["noOfPages"] = Math.ceil(parseInt(data.Count) / 10);
            state.inbox = data;
        }
    },
    getters:{
        inbox(state){
            return state.inbox;
        }
    },
    actions:{
        async sendMessage({rootGetters} , payload){
            return new Promise((resolve) => { 
            const params = {
                    body: {
                        clientName : rootGetters['companyStore/shortName'],
                        payload : payload
                    }
                }
                API.post("peoplePortalRestAPI", "/messaging/message/send", params).then((res) => {
                    if(res.status === "200"){
                        resolve({
                            snackbarSuccess : true,
                            showSnackbar : true,
                        })
                    }
                    else{
                        resolve({
                            snackbarSuccess : false,
                            showSnackbar : true,

                        })
                    }
                }).catch(() => {
                    resolve({
                        snackbarSuccess : false,
                        showSnackbar : true,

                    })
                })
            })
        },
        async sendOfferResponse({rootGetters} , payload){
            return new Promise((resolve) => { 
            const params = {
                    body: {
                        clientName : rootGetters['companyStore/shortName'],
                        payload : payload
                    }
                }
                API.post("peoplePortalRestAPI", "/messaging/message/offer/respond", params).then((res) => {
                    if(res.status === "200"){
                        resolve({
                            snackbarSuccess : true,
                            showSnackbar : true,
                        })
                    }
                    else{
                        resolve({
                            snackbarSuccess : false,
                            showSnackbar : true,

                        })
                    }
                }).catch(() => {
                    resolve({
                        snackbarSuccess : false,
                        showSnackbar : true,

                    })
                })
            })
        },
        getMessages({rootGetters, commit}, payload){
            const params = {
                body: {
                    clientName : rootGetters['companyStore/shortName'],
                    userID : payload.userID,
                    offset: payload.offset,
                    count: payload.count
                }
            }
            API.post("peoplePortalRestAPI", "/messaging/messages", params).then((res) => {
                commit("SET_LOADED_INBOX", res.data);
            });
        }
    }
}
import Cookies from "js-cookie"
export default {
    namespaced: true,
    state :{
        buyerShortList: ''
    },
    mutations:{
        SET_BUYER_SHORTLIST(state, data){
            state.buyerShortList = data;
        }
    },
    getters:{
        buyerShortList(state){
            return state.buyerShortList;
        },
    },
    actions:{
        // Need to look at how we synchronise this, do we pass them through agentos.com
        // or do we feed them onto an applicant record when opening the microsite from myprop
        getBuyerShortlist({commit}, clientName){
            var shortlist = Cookies.get('agentOS-property-shortlist');
            console.log(shortlist);
            var shortlistArray = JSON.parse(shortlist);
            console.log(shortlistArray);
            if(shortlistArray !== shortlistArray && shortlist.length > 0){
                shortlistArray = shortlistArray.filter((prop) => prop.URL.includes(clientName) && prop.ListingType === "Sale");
                console.log(shortlistArray);
                commit("SET_BUYER_SHORTLIST", shortlistArray)
            }
        }
    }
}
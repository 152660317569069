import { API } from "aws-amplify";

export default {
    namespaced: true,
    state :{
        companyDetails: null,
        companyTheme : null,
        accountTheme : null,
        companyUrls : null,
        accountUrls : null,
        shortName : null,
        currencyInfo: null
    },
    mutations:{
        SET_COMPANY_DETAILS(state,data){
            state.companyDetails = data;
        },
        SET_COMPANY_THEME(state, data){
            state.companyTheme = data
        },
        SET_ACCOUNT_THEME(state, data){
            state.accountTheme = data
        },
        SET_COMPANY_URLS(state, data){
            state.companyUrls = data
        },
        SET_ACCOUNT_URLS(state, data){
            state.accountUrls = data
            console.log(state.accountUrls)
        },
        SET_COMPANY_SHORTNAME(state, data){
            state.shortName = data;
        },
        SET_CURRENCY_INFORMATION(state, data){
            state.currencyInfo = data
            localStorage.setItem('currencyInfo', JSON.stringify(state.currencyInfo));  
        }
    },
    getters:{
        shortName(state){
            return state.shortName;
        },
        companyTheme(state){
            return state.companyTheme
        },
        accountTheme(state){
            return state.accountTheme
        },
        companyUrls(state){
            return state.companyUrls
        },
        accountUrls(state){
            return state.accountUrls
        },
        currencyInfo(state){
            let currencyInfo = state.currencyInfo;
            if(currencyInfo === null){
                if (localStorage.getItem('currencyInfo')) {
                    currencyInfo = JSON.parse(localStorage.currencyInfo)
                }
            }
            return currencyInfo
        },
        companyDetails(state){
            return state.companyDetails
        }
    },
    actions:{
        getCurrencyInformation({commit, state}){
            const params = {body: {
                clientName : state.shortName             
            }}
            API.post("peoplePortalRestAPI", "/company/currency", params).then((res) => {
                if(res.status === "200"){

                    commit("SET_CURRENCY_INFORMATION", res.data)
                }
            })
        },
        getCompanyUrlsGeneric({commit, state}){
            const params = {body: {
                clientName : state.shortName             
            }}
            API.post("peoplePortalRestAPI", "/company/urls/generic", params).then((res) => {
                if(res.status === "200"){
                    commit("SET_ACCOUNT_URLS", res.data)
                }
            })
        },
        async searchItems({rootGetters} , payload){
            return new Promise((resolve) => { 
            const params = {
                    body: {
                        clientName : rootGetters['companyStore/shortName'],
                        payload : payload
                    }
                }
                API.post("peoplePortalRestAPI", "/company/search", params).then((res) => {
                    if(res.status === "200"){
                        console.log(res);
                        resolve({
                            searchSuccess : true,
                            data : res.data,
                        })
                    }
                    else{
                        resolve({
                            searchSuccess : false,
                        })
                    }
                }).catch(() => {
                    resolve({
                        searchSuccess : false,
                    })
                })
            })
        },
    }
}
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import {Amplify} from '@aws-amplify/core';
import {Auth} from '@aws-amplify/auth';
import awsconfig from '../src/aws-exports';
import "@aws-amplify/ui-vue/styles.css";
import { VueSpinnersPlugin } from 'vue3-spinners';

loadFonts()

Amplify.configure(awsconfig);
Auth.configure(awsconfig)


createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(VueSpinnersPlugin)
  .mount('#app')

<!--This is the parent view everything in the app goes through-->
<template>
  <v-app class="tertiary">
    <div id="header-padding"></div>
    <div id="app-wrapper">
      <div id="main">
        <router-view/>
      </div>
    </div>
  </v-app>
  
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  components: {

  },
  data(){
    return {
      marginTop : 0
    }
  },
  mounted(){
    new ResizeObserver(this.headerResizer).observe(document.getElementById('app-wrapper'));
  },
  methods : {
    headerResizer(){
        let header = document.getElementById('header');
        if(header !== null && ((header.offsetHeight + 20) !== this.marginTop)){
          this.marginTop = header.offsetHeight;
        }
        this.$store.commit("SET_MARGIN_TOP", this.marginTop);
        console.log(this.marginTop)
    }
  }
})
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-family: 'Montserrat', sans-serif !important;
}
.text-h1, .text-h2, .text-h3, .text-h4, .text-h5, .text-h6{
  font-family: 'Montserrat', sans-serif !important;
}
.text-body-1, .text-body-2{
  font-family: 'Montserrat', sans-serif !important;
}
.icon{
    max-width: 128px;
}
#main{
  scroll-margin-top: 0px;
  min-height: 100vh!important;
  background-color:rgb(var(--v-theme-tertiary)) !important;
}
.loading-component{
 min-height: calc(100vh - 57px);
}
.v-layout{
  display: block;
}
.content{
  margin-top: -20px !important;;
}
.rounded{
  border-radius: 12px!important;
}
.action-menu{
  width:200px;
}
.action-menu-item{
  width:200px;
}
.v-img__img{
  position:relative !important;
}
.v-tooltip > .v-overlay__content {
  color: rgb(var(--v-theme-on-secondary)) !important;
}
iframe#webpack-dev-server-client-overlay {
  display: none;
}
.card-title{
    width:calc(100% - 30px);
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    border-bottom: 2px solid rgb(var(--v-theme-title));
    margin-left:15px !important;
}

</style>

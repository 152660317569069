import { API } from "aws-amplify";

export default {
    namespaced: true,
    state :{
        vendor: ''
    },
    mutations:{
        SET_SELECTED_VENDOR(state, data){
            state.vendor = data;
        },
        UPDATE_EXISTING_OFFER_TO_NOT_BE_ACTIONABLE(state, offer){
            console.log("Hits commit");
            let instructions = state.vendor.LinkedInstructions;
            let matchingInstructionIndex = -1
            let matchingOfferIndex = -1            
            for(let instruction of instructions){
                console.log(`Loops Instruction ${instruction.InstructionReference}`)
                console.log(instruction.InstructionOfferHistory);
                console.log(offer.OfferOID);
                matchingOfferIndex = instruction.InstructionOfferHistory.indexOf(offer);
                if(matchingOfferIndex !== -1){
                    console.log("Finds Offer")
                    matchingInstructionIndex = instructions.indexOf(instruction);
                    break;
                }
            }
            if(matchingInstructionIndex !== -1 && matchingOfferIndex !== -1){
                console.log("Has found offer");
                state.vendor.LinkedInstructions[matchingInstructionIndex].InstructionOfferHistory[matchingOfferIndex].Actionable = false;
                console.log(state.vendor.LinkedInstructions[matchingInstructionIndex].InstructionOfferHistory[matchingOfferIndex]);
            }
        }
    },
    getters:{
        vendor(state){
            return state.vendor;
        },
        linkedInstructionsCount(state){
            if(state.vendor === ''){
                return 0;
            }
            else{
                return state.vendor.LinkedInstructions.length;
            }
        },
        instructions(state){
            if(state.vendor === ''){
                return null;
            }
            else{
                return state.vendor.LinkedInstructions;
            }
        }
    },
    actions:{
        getVendor({commit}, payload){
            const params = {body: {
                clientName : payload.clientName,
                vendorID : payload.vendorID             
            }}
            API.post("peoplePortalRestAPI", "/accounts/vendor", params).then((res) => {
                if(res.status === "200"){
                    console.log(res);
                    commit("SET_SELECTED_VENDOR", res.data)
                }
            })
        }
    }
}